import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const accountRoutes: CustomRouteRecordRaw = {
  path: 'account',
  name: 'account',
  component: EmptyLayout,
  meta: {
    title: '账号管理',
    iconfont: 'icon-account-pin-box-line1',
    requiredPermission: 'ACCOUNT'
  },
  children: [
    {
      path: 'role-list',
      name: 'roleList',
      component: () => import('@/views/account/role-list.vue'),
      meta: {
        title: '角色管理',
        requiredPermission: 'ROLE_LIST',
      },
    },
    {
      path: 'team',
      name: 'team',
      component: () => import('@/views/account/team.vue'),
      meta: {
        title: '部门管理',
        requiredPermission: 'TEAM',
      },
    },
    {
      path: 'team-account',
      name: 'teamAccount',
      component: () => import('@/views/account/team-account.vue'),
      meta: {
        title: '部门账号管理',
        requiredPermission: 'TEAM_ACCOUNT'
      },
    }
  ],
}