import { request } from '@/utils/request'

// 后台管理系统账号
const adminApi = {

  /** 【】获取账号列表 */
  getAdmins: params => request.get('/admin_users/index', params),

  /** 【】 编辑 新增账号 */
  addAdmin: params => request.post('/admin_users/store', params),

  /** 【】更新账号 */
  updateAdmin: (id, params) => request.post(`/admin_users/update/${id}`, params),
}

// 角色管理
const roleApi = {

  getRoleList: params => request.get('siteRole/index', params),

  updateRole: params => request.post('siteRole/store', params),

  delRole: id => request.get(`siteRole/destroy/${id}`)
}

// 部门
const teamApi = {

  // 部门列表
  getTeamList: params => request.get('siteTeam/tree', params),

  // 删除部门
  delTeam: id => request.get(`siteTeam/destroy/${id}`),

  // 添加 / 修改部门
  updateTeam: params => request.post('siteTeam/store', params),
}

// 部门账号
const userApi = {
  getCompanyUserList: params => request.get('company/companyUsers/index', params),

  // 新增/ 编辑人员
  updateCompanyUser: params => request.post('company/companyUsers/store', params)
}

// 菜单列表
const menuApi = {
  // 获取菜单列表
  getMenuList: params => request.get('siteRoutes/userRoutes', params),


}


// 帮助中心Article/HelpArticle/list
const helpApi = {

  /* 文章列表 */
  getHelpArticleList: params => request.post('Article/HelpArticle/lists', params),

  /* 添加文章 */
  addHelpArticleList: params => request.post('Article/HelpArticle/store', params),

  /* 修改文章 */
  updataHelpArticleList: (id, params) => request.post(`Article/HelpArticle/update/${id}`, params),

  /* 删除文章 */
  deleteHelpArticleList: id => request.post('Article/HelpArticle/delete', { id }),

  /* 查看文章 */
  showHelpArticleList: (params, id) => request.post(`Article/HelpArticle/show/${id}`, params)
}

/** 【】组织 */
const clientApi = {

  /** 【】获取组织列表 */
  getClientList: params => request.get('admin_group/index', params),

  addClient: params => request.post('admin_group/store', params),

  updateClient: (id, params) => request.post('admin_group/store', {
    id,
    ...params
  }),

  /** 【】获取切换组织列表 */
  getClientSelectList: () => request.get('admin_group/temp'),

  /** 【】切换组织列表 */
  changeUserGroup: params => request.post('admin_users/temp_group', params),

}


export { adminApi, roleApi, teamApi, userApi, helpApi, clientApi, menuApi }


